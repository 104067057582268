import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
// import LogoImg from "../assets/svg/Logo";
import LogoImg from "../assets/images/logo-dark.png";
import "../assets/css/footer.css"

const Contact = () => {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className=" footer"  style={{backgroundColor:"#DBEAFE"}} >
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "10px 0"}}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              {/* <LogoImg />
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Fanatic
              </h1> */}
              {/* <img id="main-logo" src={LogoImg} alt="" height={25} width={80} className="logo" /> */}
            </Link>
            <StyleP style={{color:"#0B093B"}} className="whiteColor font13">
              © {getCurrentYear()} - <span className="white font13" ><a className=""  href="https://synchrony.co.ke" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#903CFF"}}>Synchrony Technologies Ltd</a>. All Rights Reserved</span> 
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  left:0;
  bottom:0;
  right:0;
`;


const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
export default Contact