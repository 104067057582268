import React, { useState, useEffect } from "react";
import { Card, CardBody } from "react-bootstrap";
// import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Accordion from 'react-bootstrap/Accordion';
import "../style/slider.css";
import Accordionitem from "../components/Accordionitem";

import sliderImage from "./SliderImage";
import FAQ from "../components/FAQ";
// import Accordionitem from "../components/Accordionitem";

{/* <link href="https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap" rel="stylesheet"></link> */ }

const items = [
  {
    imageUrl: "http://via.placeholder.com/160x160",
    title: "something"
  },
  {
    imageUrl: "http://via.placeholder.com/160x160",
    title: "something two"
  },
  {
    imageUrl: "http://via.placeholder.com/160x160",
    title: "something three"
  },
  {
    imageUrl: "http://via.placeholder.com/160x160",
    title: "something four"
  }
];


<style>
  {/* @import url('https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap'); */}
</style>

const len = sliderImage.length - 1;
const Header = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [open, setOpen] = useState(false);

  const toggle = (index) => {
    if (open === index){
      return setOpen(null)
    }
    setOpen(index)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500
  }
  const slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500
  }

  const accordionData = [
    {
      title:"This is title 1",
      desc:"Description is down hete"
    },
    {
      title:"This is title 2",
      desc:"Description is down hete"
    },{
      title:"This is title 3",
      desc:"Description is down hete"
    },
  ];
  const toTitleCase = (s) => {
    if (typeof (s) === 'string' && s.length > 0) {
      const words = s.split(' ')
      if (Array.isArray(words) && words.length > 0) {
        if (words.length === 1) {
          const word = words[0]
          const matches = word.charAt(0).match(/\w+/i)
          const lines = word.split('\n')
          if (Array.isArray(lines) && lines.length > 1) {
            return lines.map(line => {
              return toTitleCase(line)
            }).join('\n')
          } else if (Array.isArray(matches)) {
            return word.split('').map((c, i) => {
              if (i === 0) {
                return c.toUpperCase()
              }
              return c.toLowerCase()
            }).join('')
          } else {
            return word.charAt(0).concat(toTitleCase(word.slice(1)))
          }
        } else {
          return words.map(word => toTitleCase(word)).join(' ')
        }
      }
    }
    return ''
  }

  const openCRM = () => {
    window.open(
      "#",
      "_blank"
    );
  };

  const openHr = () => {
    window.open(
      "https://hr.theonlineapps.com/newInsync/auth/signin-2",
      "_blank"
    );
  };

  const openHrm = () => {
    window.open(
      "https://accounts.theonlineapps.com/newInsync/auth/signin-2",
      "_blank"
    );
  };

  const openInventory = () => {
    window.open(
      "#",
      "_blank"
    );
  };

  const openPension = () => {
    window.open(
      "#",
      "_blank"
    );
  };

  return (

    <div
      className="container "
      style={{ minHeight: "740px", paddingTop: "90px", width: "100%" }}
    >
      <div className="row align-items-start font-display">
        {/* <div className="col-md-1"></div> */}
        <div className="col-md-4 col-sm-12 text-center">
          <h6 className="extraBold font20" >Hosted applications </h6>
          <h6 className="extraBold font20">for</h6>
          <h6 className="extraBold font20">Small and Medium Businesses</h6>
          {/* <button 
          variant="outline-primary" className="btn btn-primary mt-4 hoover" 
          type="button" 
          onClick={() => openHr()}>Get Started</button> */}
        </div>
        <div className="col-md-3 col-sm-12 mt-3"  >
          <Card className="border-0" style={{ zIndex: 4 }}>
            <Card.Header style={{ border: "none" }} >
              <Card.Title style={{ fontSize: 19 }} >FEATURED APPS</Card.Title>
            </Card.Header>
          </Card>
          <FAQ />
          {/* <section className="bg-[3d3dbp] grid place-items-center">
            <div className="px-[40px] max-w-[600px]">
              {accordionData.map((data, index) => {
                return <Accordionitem 
                key={index} 
                open={index === open} 
                title={data.title} 
                desc={data.desc} 
                toggle={() => toggle(index)}/>;
              })}
            </div>
          </section> */}
        </div>
        <div id="secondary" className="col-md-4 col-sm-12 mt-3">
          <Card className="border-0" style={{ zIndex: 4 }}>
            <Card.Header style={{ border: "none" }}>
              <Card.Title>Active Links</Card.Title>
            </Card.Header>
            <CardBody>
              <ol className="colored-orderlist">
                <li className="colored-list" type="1"><a href="https://www.weforum.org/agenda/2023/06/emerging-technologies-innovation-2023/" target="_blank" className="font-display" rel="noreferrer">Emerging Technologies</a><br /></li>
                <li type="1"><a href="https://www.simplilearn.com/top-technology-trends-and-jobs-article" target="_blank" className="font-display" rel="noreferrer">22 New Technology Trends for 2024: New Tech Horizons</a><br /></li>
                <li type="1"><a href="https://www.dre.vanderbilt.edu/~schmidt/PDF/CSI-article.pdf" target="_blank" className="font-display" rel="noreferrer">Distributed Technology: Objects and beyond</a></li>
              </ol>
            </CardBody>
          </Card>
          {/* <div className="slider-container">
            <SliderContent
              activeIndex={activeIndex}
              sliderImage={sliderImage}https://master.theonlineapps.com
            />
            <Arrows
              prevSlide={() =>
                setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
              }
              nextSlide={() =>
                setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
              }
            />
            <Dots
              activeIndex={activeIndex}
              sliderImage={sliderImage}
              onclick={(activeIndex) => setActiveIndex(activeIndex)}
            />
          </div> */}
        </div>
        <div className="col-md-1"></div>
      </div>
      <div className="row">
        {/* <div className="row" style={{ marginTop: "20px" }}> */}
        <div className="col-md-4"></div>
        <div className="col-md-3"></div>
        <div className="col-md-4">

        </div>
        <div className="col-md-2">

        </div>
      </div>
      <div className="row">
        {/* <div className="row" style={{ marginTop: "50PX" }}> */}
        <div className="col-md-1"></div>
        {/* <div className=" sm:w-4/12 px-0 rounded-md relative flex items-center col-md-10">
          <MdChevronLeft className="opacity-50 cursor-pointer hover:50" onClick={slideLeft} size={50} />
          <div id="slider" className="rounded-md w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth">
            {items.map((item) => (
              // <img src={item.imageUrl} alt="..." className="w-[350px] h-[150px] inline-block " />
              <img
                className="w-[350px] h-[150px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-500"
                src={item.imageUrl} alt="/" />
            ))}
          </div>
          <MdChevronRight className="opacity-50 cursor-pointer hover:70" onClick={slideRight} size={50} />
        </div> */}
        <div className="col-md-1">
        </div>
      </div>
    </div>
  );
};


export default Header;
